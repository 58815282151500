'use client';

import { FunctionComponent } from 'react';

import { CategoryLink } from '../CategoryLink';

import { MobileDrawer } from '../../MobileDrawer';
import { MobileNavItem } from '../../MobileNavItem';
import { IBlockProps } from './IBlockProps';

import S from '../../../styles.base.module.scss';

/**
 * Renders a button for the mobile navigation, which once pressed
 * opens the Drawer component containing the links of a block.
 */
export const CategoryBlock: FunctionComponent<IBlockProps> = ({ block }) => {
  /**
   * The ICategoryBlock is a weird structure, it is an array, which
   * pretty much exclusively contains a single item, which contains the actual links.
   */
  const [data] = block.links ?? [];
  const label = block.label ?? data.label;

  return (
    <MobileDrawer label={label}>
      <ul className={S.nav}>
        <MobileNavItem>
          <span className={`${S.navLink} ${S.navLinkHeader}`}>{label}</span>
        </MobileNavItem>
        {data.links?.map((link) => {
          return (
            <CategoryLink key={`${link.id}-category-link`} category={link} />
          );
        })}
      </ul>
    </MobileDrawer>
  );
};
