'use client';

import { FunctionComponent, Suspense } from 'react';

import { POSStoreBanner } from '@/react/components/endless-aisle/POSStoreBanner';
import { ExchangeBanner } from '@/react/components/exchanges/ExchangeBanner';
import { useReturnsFlowState } from '@/react/components/returns/useReturnsFlowState';
import type { MaybePromise, Nullable } from '@/type-utils';

import { DirectContent } from '@/react/components/content/core/DirectContent';
import { type IContent } from '@/services/isomorphic/ContentService';

export interface IHeaderBannersProps {
  /**
   * The content that represents the banner.
   */
  contentBanner: Nullable<MaybePromise<IContent>>;
}

/**
 * Renders the possible header banners and manages their state.
 */
export const HeaderBanners: FunctionComponent<IHeaderBannersProps> = ({
  contentBanner = null
}) => {
  const returnsFlowState = useReturnsFlowState();

  const exchangeBannerNode = returnsFlowState?.isExchangeModeActive && (
    <ExchangeBanner flowState={returnsFlowState} />
  );

  return (
    <>
      {contentBanner && (
        <Suspense>
          <DirectContent content={contentBanner} />
        </Suspense>
      )}
      {exchangeBannerNode}
      <POSStoreBanner />
    </>
  );
};
