'use client';

import {
  createContext,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren
} from 'react';

import type { IDrawerActions } from './types';

/**
 * The context for all open drawers.
 */
export const DrawerContext = createContext<Set<string> | null>(null);

/**
 * The context for all drawers' actions. Should not be used outside of the DrawerProvider.
 */
export const DrawerActionsContext = createContext<IDrawerActions | null>(null);

/**
 * Provides the context for all drawers' actions, as well as the open drawers state.
 */
export const DrawerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openDrawers, setOpenDrawers] = useState<Set<string>>(
    () => new Set<string>()
  );

  const actions = useMemo<IDrawerActions>(
    () => ({
      openDrawer: (id: string) => {
        setOpenDrawers(new Set(openDrawers).add(id));
      },
      closeDrawer: (id: string) => {
        const newSet = new Set(openDrawers);
        newSet.delete(id);
        setOpenDrawers(newSet);
      },
      closeAllDrawers: () => {
        setOpenDrawers(new Set());
      }
    }),
    [openDrawers]
  );

  return (
    <DrawerContext.Provider value={openDrawers}>
      <DrawerActionsContext.Provider value={actions}>
        {children}
      </DrawerActionsContext.Provider>
    </DrawerContext.Provider>
  );
};
