import { FunctionComponent } from 'react';
import { type ICategoryBlock } from '@/react/components/navigation/deprecatedInterfaces/ICategoryBlock';
import { CSSTransition } from 'react-transition-group';

import { Block } from './Block';

import S from '../styles.base.module.scss';

/**
 * Props for the Section (vertical column that may contain 1 or more blocks)
 * in the Desktop navigation bar dropdown.
 */
interface ISectionProps {
  /** A single section from the navigation content. */
  section: ICategoryBlock;

  /** Wether the section is visible (Tied to the Dropdown visibility prop). */
  isVisible: boolean;

  /** Index of the section (used for transition delay). */
  index: number;

  /** Animation length (CSS transition property). */
  transitionMS?: number;

  /** The delay (in ms) for the animation. */
  transitionDelayMS?: number;
}

/**
 * Renders blocks of links (a Section). Most of the time there is only
 * one block per section but this component can handle an array
 * of blocks on top of eachother.
 */
export const Section: FunctionComponent<ISectionProps> = ({
  section,
  isVisible,
  index,
  transitionMS = 300,
  transitionDelayMS = 0
}) => {
  return (
    <CSSTransition
      in={isVisible}
      timeout={0}
      classNames={{
        enterDone: S.sectionEntered,
        exit: S.sectionEntered,
        exitActive: S.sectionExiting,
        exitDone: S.sectionHidden
      }}
    >
      <li
        role="menuitem"
        className={S.section}
        style={{
          transition: `${transitionMS}ms`,
          transitionDelay: `${transitionDelayMS}ms`
        }}
      >
        {section.links.map((block) => {
          return <Block key={block.id} block={block} />;
        })}
      </li>
    </CSSTransition>
  );
};
