'use client';

import type { BreakpointConfigWithNode } from '../getBreakpointConfigs';

export interface IInternalBreakpointGroupProps {
  /** A list of breakpoint configurations. */
  configs: Array<BreakpointConfigWithNode>;
}

// export { ClassicBreakpointGroup as InternalBreakpointGroup } from './ClassicBreakpointGroup';
export { OptimizedBreakpointGroup as InternalBreakpointGroup } from './OptimizedBreakpointGroup';
