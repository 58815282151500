import type { FC } from 'react';
import { SkipRenderOnServer } from '../../utils/SkipRenderOnServer';
import { ClientOnlyPOSStoreBanner } from './internal/ClientOnlyPOSStoreBanner';

/** POS banner that displays above or below the header when the Magtek / Endless Isle POS is enabled. */
export const POSStoreBanner: FC = () => {
  return (
    <SkipRenderOnServer>
      <ClientOnlyPOSStoreBanner />
    </SkipRenderOnServer>
  );
};
